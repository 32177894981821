.error-page {
    padding-top: 5rem;

    h1 {
        font-size: 1.5rem;
        text-align: left;
        margin: 0 0 1rem 0;

        &:before {
            margin-left: 0;
        }
    }

    .button {
        margin-left: 0;
    }

    p {
        font-weight: bold ;
        text-align: center;
    }

    @include media-breakpoint-up(lg) {
        h1 {
            font-size: 2rem;
        }
    }
}
