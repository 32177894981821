.home {

    .hero {
        height: 100vh;
        .logo, .catch-phrase   {
            object-fit: inherit;
        }
    }

    .sections {
        &.brasserie {
            @include media-breakpoint-up(lg) {
                display: none;
            }

            background-color: $black;
            padding: 30px 15px;
            section.brasserie-content {
                display: flex;
                align-items: center;
                justify-content: space-around;

                @include media-breakpoint-up(sm) {
                    display: none;
                }
                a {
                    color: white;
                    font-size: 16px;
                    font-weight: lighter;
                    text-decoration: underline;
                }
                img {
                    width: inherit;
                    height: 54px;
                }
            }

        }
    }

    section.actualites {
        /*background: url("../../../images/alcoolapapa/home/actualities/actualite-background-mobile.svg");
        background-size: cover;
        @include media-breakpoint-up(lg){
            background: url("../../../images/alcoolapapa/home/actualities/actualite-background-desktop.svg");
            background-size: cover;
        }*/

        h1 {
            font-size: 34px;
            font-family: "Playfair Display", "sans-serif";
            line-height: 36px;
            letter-spacing: 3px;
            color: white;
            text-transform: uppercase;
            margin: 15px 0 40px 0;
            text-align: center;

            @include media-breakpoint-up(lg) {
                color: $primary;
                font-size: 48px;
                margin: 60px 0 60px 0;
            }
        }

        &:before {
            height: 37px;
            background: url("/images/alcoolapapa/separator.svg") repeat-x;
            z-index: 1;

            content: "";
            top: 0;
            bottom: unset;
            display: inherit;
            position: relative;
        }

        &:after {
            height: 37px;
            background: url("/images/alcoolapapa/separator.svg") repeat-x;
            z-index: 1;

            content: "";
            bottom: 0;
            display: inherit;
            position: relative;
        }

        .articles {
            .container {
                padding: 0 16px;
                overflow-x: scroll;
                scroll-snap-type: x mandatory;
                display: flex;
                gap: 16px;
                margin-bottom: 30px;

                @include media-breakpoint-up(lg) {
                    overflow-x: inherit;
                    gap: 20px;
                    justify-content: center;
                }
                a {
                    .item {
                        color: white;
                        width: 259px;
                        @include media-breakpoint-up(lg) {
                            width: fit-content;
                            max-width: 453px;
                        }

                        .content {
                            .img {
                                .mobile {
                                    width: 259px;
                                    height: 215px;
                                }
                                .desktop {
                                       // width: 100%;
                                        height: 281px;
                                }
                            }

                            h2 {
                                font-size: 16px;
                                font-weight: 600;
                                margin: 16px 0;
                                padding: 0 10px;
                                font-family: inherit;
                                line-height: inherit;
                                letter-spacing: 2px;
                                text-align: center;
                                color: $primary;
                                min-height: 39px;
                                @include media-breakpoint-up(lg) {
                                    padding: 0 20px;
                                    font-size: 25px;
                                    line-height: 36px;
                                }
                            }
                        }

                        .date {
                            text-align: center;
                        }
                        .description {

                            text-align: center;
                            font-size: 16px;
                            @include media-breakpoint-up(lg) {
                                padding: 0 20px;
                                color: $black;
                            }

                        }
                    }
                }
            }
        }

        a.actualites-btn {
            width: 280px;
            display: inherit;
            background: $primary;
            margin: auto;
            text-align: center;
            padding: 9px 0;
            color: $white;
            letter-spacing: 4px;
            transition: 0.3s ease;
            @include media-breakpoint-up(lg) {
                margin-bottom: 60px;
            }
            &:hover {
                color: $primary;
                background: $white;
            }
        }
    }


    .section-with-overlay {
        h1 {
            margin: 0;
        }
        margin: 0 !important;

        .cover {
            z-index: 0 !important;
        }
        .container {
            z-index: 0 !important;
            @include media-breakpoint-down(md) {
                padding: 0 32px;
            }
            .content {
                h1 {
                    text-align: center;
                    font-size: 34px;
                    font-family: "Playfair Display", "sans-serif";
                    font-style: normal;
                    text-transform: uppercase;
                    line-height: 36px;
                    letter-spacing: 3px;
                    margin-bottom: 30px;
                    @include media-breakpoint-up(lg) {
                        font-size: 48px;
                        line-height: normal;
                    }
                }
                a {
                    padding: 9px 33px;
                    display: inherit;
                    background: $primary;
                    text-align: center;
                    letter-spacing: 4px;
                    transition: 0.3s ease;

                    &:hover {
                        background: $white;
                        color: $primary;
                        border-color: $white;
                    }
                }
                img.separator {
                    height: auto;
                    width: auto;
                    max-width: 100%;
                }
            }
        }
    }

    .contact {
        margin-bottom: 0;
    }
}
