/* ------------------- breakpoints ------------------------- */
$breakpoints: (
    md: 750px,
    lg: 1024px,
    xl: 1350px,
);

/* -------------------- colors ----------------------------- */
$primary: #C04D00;
$secondary: #E6BEA1;
$blue: #3490dc;
$indigo: #6574cd;
$purpleMarker: #801655;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$white: white;
$black: black;
$gray: #E5E5E5;
