.hero {
    position: relative;
    color: $white;
    min-height: 61px;
    @include media-breakpoint-up(lg) {
        background-color: white;
        min-height: 88px;
    }

    .nocover {
    }

    /*    .img-section {
            height: 100%;
            display: flex;
            padding: 80px 20px;
            align-items: center;
            flex-direction: column;

            @include media-breakpoint-up(lg) {
                padding: 80px 200px;
            }*/
    img {
        height: 100%;
        object-position: center;
        object-fit: cover;
    }

    img.logo {
        width: 193px;
        height: 167px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 80px);
        @include media-breakpoint-up(lg) {
            //  width: 240px;
            // height: 192px;
            top: 0;
            left: 0;
            transform: translate(135px, 80px);
        }
    }

    img.catch-phrase {
        height: 95px;
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;

        @include media-breakpoint-up(lg) {
            height: 200px;
            top: 70%;
            left: 50%;
        }
    }


    .img-background {
        position: absolute;
        height: 100%;
        @include media-breakpoint-up(lg) {
            height: inherit;
        }
        //  z-index: -1;
    }


    .cover {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.14) 100%);
    }

    &::after,
    &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.14) 100%);
    }

    &::after {
        height: 37px;
        top: unset;
        bottom: 0;
        transform: translateY(50%);
        background: url("/images/alcoolapapa/separator.svg") repeat-x;
        z-index: 1;
    }


    h1 {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 100%;
        padding: 0 2rem;
        font-size: to-rem(20);
        text-transform: uppercase;

        strong {
            display: block;
            font-weight: bold;
            font-size: to-rem(50);
        }
    }

    @include media-breakpoint-up(lg) {
        img {
            height: 100vh;
        }

        h1 {
            font-size: to-rem(50);
            bottom: 20%;

            strong {
                display: block;
                font-weight: bold;
                font-size: to-rem(70);
            }
        }
    }
}
