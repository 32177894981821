#map {
    margin-top: 20px;
}

.filter-container {

    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Staatliches";
    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

.container-center {
    text-align: center;

    .gamme-item {
        background-color: black;
    }
    .category-item {
        background-color: #C04D00;
    }
}

.gammes-container {
    //display: grid;
    //grid-template-columns: repeat(1, 1fr);
    //flex-wrap: wrap;
    //gap: 1rem;
    //flex-direction: column;
    //justify-content: center;
    //@include media-breakpoint-up(md) {
    //    flex-direction: row;
    //    grid-template-columns: repeat(5, 1fr);
    //}

    .gamme-item {
        color: $white;
        text-transform: uppercase;
        font-weight: 400;
        border-radius: 23px;
        //border: 1px solid $white;
        padding: 10px 30px;
        transition: all ease .8s;
        display: inline-block;
        margin: 0.5rem;

        &:hover, &.active {
            background-color: $white;
            color: $black;
        }
    }
}

.category-container {
    //display: flex;
    //flex-direction: column;
    //margin-top: 25px;
    //gap: 1rem;
    //flex-wrap: wrap;
    //justify-content: center;
    //@include media-breakpoint-up(md) {
    //    justify-content: normal;
    //    flex-direction: row;
    //}

    .category-item {
        color: $white;
        text-transform: uppercase;
        font-weight: 400;
        border-radius: 23px;
        //border: 1px solid $white;
        padding: 10px 30px;
        transition: all ease .8s;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        display: inline-flex;
        margin: 0.5rem;

        svg {
            width: 20px;
            height: 20px;
            fill: $white;
            transition: all ease .2s;
        }

        &.active {
            background: $white;
        }
    }
}

.filter-container .tooltip {
    //color: $white;
    margin-top: 40px;
    display: flex;
    justify-content: flex-end;

    .reset {
        flex: 1;
    }

    button {
        font-size: 1rem;
        text-transform: uppercase;
        text-decoration: underline;
        transition: all ease .8s;

        &:hover {
            color: $primary;
        }
    }
}

.autocomplete-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: $white;
    gap: 1rem;
    margin-top: 1rem;
    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    .filter-button {
        font-family: "Staatliches";
        background-color: $primary;
        padding: 20px;
        flex: 1;
        text-transform: uppercase;
        transition: 0.3s ease;

        &:hover {
            background-color: $white;
            color: $primary;
        }
    }

    .input {
        flex: 1;
        display: flex;
        border: 1px solid $primary;
        justify-content: center;
        align-items: center;
        padding: .5rem;
        box-sizing: border-box;

        input {
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            color: $black;
            text-transform: uppercase;
            font-weight: 400;
            font-family: "Staatliches";
        }
    }

    svg > path {
        fill: $primary;
    }
}

.shopContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 10px;
    grid-gap: 15px;
    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
        margin-top: 30px;
        grid-gap: 30px;
    }

    align-items: start;
    margin-bottom: 100px;
}

.shop-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    padding-bottom: 10px;
    height: 100%;

    .shop-categories {
        display: flex;
        justify-content: center;
        gap: 5px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 400;
    }

    .title {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: bold;
        font-family: "Staatliches";
        letter-spacing: 0.5px;
    }

    .location {
        color: $primary;
        font-weight: bold;
        font-size: 18px;
        max-width: 50%;
        margin: 0 auto;
    }

    .description {
        font-size: 14px;
        margin: 0 !important;
    }

    .shop-products {
        display: flex;
        justify-content: center;
        gap: 5px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
    }
}

.nbResult {
    font-size: 30px;
    text-transform: uppercase;
    color: $primary;
    font-weight: 400;
    margin-top: 35px;
}

.quick-filter {
    display: flex;
    gap: 1rem;
    margin-top: 10px;
    font-family: "Staatliches";

    .selected-gammes {
        display: flex;
        gap: .5rem;
    }

    .selected-categories {
        display: flex;
        gap: .5rem;
    }

    .gamme-item, .category-item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .2rem;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
    }
}

.distributor-container {
    font-family: "Staatliches";

    .location {
        color: black;
        margin-bottom: 20px;
    }

    .gammes {
        margin-top: 10px;
        font-size: 11px;
        background: none !important;
    }
}
