.hero-page{
    position: relative;
    margin-bottom: 2rem;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    //height: 630px;
    max-height: 100vh;
    background-position: center;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
        //height: 500px;
        background-position: center;
    }
    h1 {
        position: absolute !important;
        top: 50%;
        left: 50%;
        color: white;
        transform: translate(-50%, -50%) !important;
        font-family: "Playfair Display", "sans-serif" !important;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 4px;
        text-transform: uppercase;
        height: fit-content;
        margin: 0 !important;
        text-shadow: 0 2px 14px #000 !important;
    }

    img {
        height: inherit;
        aspect-ratio: 4.5/3;
    }
}
