.beer-page {
    text-align: center;
    background-image: url("/images/alcoolapapa/product/product-bg-mobile.jpg");

    @include media-breakpoint-up(lg) {
        background-image: url("/images/alcoolapapa/product/product-bg-desktop.jpg");
    }

    .title-with-perso {
        @include media-breakpoint-up(lg) {
            min-height: 400px;
            margin-top: -20px;
        }
    }

    .cover {
        padding-top: 62px;
        position: relative;

        @include media-breakpoint-up(lg) {
            padding-top: 86px;
        }


        .columns {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            text-align: center;
            max-width: 1024px;
            margin-bottom: 30px;
            @include media-breakpoint-up(lg) {
                padding: 0 5rem;
            }
            @include media-breakpoint-up(xl) {
                padding: 0 1rem;
            }
        }

        h1 {
            margin-bottom: 1rem;
        }
    }

    .beer.left {
        img {
            max-height: inherit;
        }
    }

    .right {

        position: relative;
        z-index: 1;

        h1 {
            margin: 0 auto 1rem auto;
            // margin: 2rem auto 1rem auto;
            color: $primary;
            font-size: 30px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 4px;
            text-transform: uppercase;
            font-style: normal;
            font-family: inherit;

            @include media-breakpoint-up(lg) { // with character
                min-height: 95px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
            }

            @include media-breakpoint-up(lg) {
                font-size: 38px;
            }


            &:after {
                content: '';
                display: block;
                background: url(/images/alcoolapapa/bandeau.svg) no-repeat center;
                height: 21px;
                margin-top: 14px;
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
        }

        p {
            margin-bottom: 0.75em;
            font-style: normal;
            font-weight: 400;
            line-height: calc(27 / 16 * 1em);
        }

        .allergens {
            margin-top: 2rem;
        }

        .beer-info,
        .beer-introduction {
            font-family: Oswald, "sans-serif";
            color: $black;
            font-size: 24px;
            font-style: normal;
            line-height: 30px;
            text-transform: uppercase;
            margin: 1rem auto;
        }

        .beer-introduction {

            &:after {
                content: '';
                display: block;
                background: url(/images/alcoolapapa/bandeau-marron.svg) no-repeat;
                height: 29px;
                margin-top: 10px;
                background-size: contain;
                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }
        }

        .sap-character {
            display: none;
            pointer-events: none;
            @include media-breakpoint-up(lg) {
                display: inherit;
                position: absolute;
                z-index: 2;
                content: "";
                top: 10px;
                right: 15px;
                transform: translate(30%, 0);
            }
        }
    }

    & > .container {
        padding-bottom: 70px !important;

        .block-text.normal {
            text-align: center;
            margin: auto;
        }

        .center {
            margin: 2rem auto;
        }
    }

    .in-this-gamme {
        padding-bottom: 80px;

        .more-products {
            background: $primary;
            color: $white;
            transition: 0.3s ease;
            padding: 9px 1rem;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 4px;
            text-transform: uppercase;

            &:hover {
                color: $primary;
                background: $white;
                transition: 0.3s ease;
            }
        }

        h1 {
            color: $white;
            font-size: 34px;
            font-style: normal;
            line-height: 36px;
            letter-spacing: 3px;
            text-shadow: 0 2px 14px #000;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                color: $primary;
                font-size: 48px;
                line-height: normal;
                letter-spacing: 4px;
                text-shadow: none;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: "Playfair Display", "sans-serif";
                font-style: normal;
                font-weight: 600;
            }
        }

        .container {
            grid-template-columns: repeat(1, 1fr);
            display: grid;
            padding: 2rem 1rem;
            gap: 3rem;
            margin-bottom: 50px;

            &:not(.container-gamme-1) {
                @include media-breakpoint-up(md) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @include media-breakpoint-up(xl) {
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            .beer {
                @include media-breakpoint-down(lg) {
                    color: $white;
                }
                @include media-breakpoint-down(md) {
                    padding: 0 3rem !important;
                }
            }
        }

        &:before {
            content: "";
            height: 37px;
            top: unset;
            bottom: 0;
            transform: translateY(-50%);
            background: url("/images/alcoolapapa/separator.svg") repeat-x;
            z-index: 1;

            display: inherit;
            position: relative;
        }
    }


    @include media-breakpoint-up(lg) {
        .cover {
            .columns {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                gap: 5rem;
                text-align: left;
            }
        }

        .right {
            .sizes, .allergens {
                justify-content: flex-start;

            }

            .abu-alchool-edition {
                justify-content: flex-start;
            }

            h1 {

                text-align: left;
                margin-left: 0;

                &::before {
                    margin: 0;
                }
            }

            .alcohol, .beer-introduction {
                margin-left: 0;
            }


        }
    }
}
