html {
    font-family: "Roboto", sans-serif;
    line-height: 1.3;
    font-size: 1.15rem;
    overflow-x: hidden;
}

body.alcoolapapa {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    h1,
    h2,
    h3,
    a,
    .date {
        font-family: "Staatliches", "sans-serif";
    }

    h1 {
        font-size: to-rem(40);
        text-align: center;
        position: relative;
        margin: 4rem 0;
        font-family: "Oswald", "sans-serif";
        @include media-breakpoint-up(lg) {
            font-size: to-rem(70);
        }
    }

    p {
        color: $black;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Oswald", "sans-serif";
        font-style: normal;
    }



    h2 {
        font-size: 1.2rem;
        @include media-breakpoint-up(lg) {
            font-size: to-rem(25);
        }
    }

    img {
        display: block;
        width: 100%;
        object-fit: cover;

        &.desktop {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            &.mobile {
                display: none;
            }
            &.desktop {
                display: block;
            }
        }
    }

    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    .container {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 1rem;
    }

    .button {
        font-family: "Staatliches", "sans-serif";
        letter-spacing: 1px;
        background-color: $primary;
        display: block;
        width: fit-content;
        text-align: center;
        margin: 2rem auto;
        color: $white;
        padding: 0.5rem 2rem;
        transition: 0.3s ease;

        &:hover {
            background-color: $white;
            color: $primary;
        }

        &.white {
            background-color: $white;
            color: $primary;
        }

        &.radius {
            border-radius: 999px;
        }

        &.outline {
            // background-color: $white;
            border: 2px solid $primary;
            background-color: transparent;
        }

        &#oldest-yes-button {
            transition: 0.3s ease;
            border: 2px solid $primary;

            &:hover {
                background-color: transparent;
                transition: 0.3s ease;
            }
        }
    }

    .section-with-overlay {
        position: relative;
        color: $white;
        margin: 4rem 0;

        &.right {
            .container {
                .content {
                    @include media-breakpoint-up(lg) {
                        margin-left: auto;
                        margin-right: 117px;

                    }
                }
            }
        }

        &.left, &.distillerie-section {
            .container {
                .content {
                    @include media-breakpoint-up(lg) {
                        margin-right: auto;
                        margin-left: 117px;
                    }
                    @include media-breakpoint-up(lg) {
                        margin: 0;
                    }
                }
            }

        }

        .container {
            //  min-height: 452px;
            min-height: 500px;
            display: grid;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 2;
        }

        .cover {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;

            img {
                height: 100%;

                @include media-breakpoint-up(lg) {
                    object-fit: cover;
                }

            }
        }

        .content {
            background-color: rgba(0, 0, 0, 0.6);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-inline: 15px;
            max-width: 482px;
        }

        @include media-breakpoint-up(md) {
            .container {
                grid-template-columns: 1fr 1fr;
            }

            &.right .content {
                grid-column: 2 / -1;
            }
        }
    }

    .beer {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        img {
            max-height: 295px;
            max-width: 100%;
            margin: 0 auto;
            width: auto
        }

        p {
            font-size: 0.8rem;
        }


        h2 {
            //width: max-content;
            margin: 0 auto;

        }
    }


    .abu-alchool-edition {
        display: flex;
        gap: 1rem;
        margin-bottom: 1rem;
        justify-content: center;
    }

    .ibu, .alcohol, .edition {
        font-family: "Staatliches", "sans-serif";
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: .1rem .3rem;
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
    }


    .edition {
        background-color: $red;
        justify-content: center;
        color: $white;
        border-color: $red;

    }

    .ibu {
        background-color: transparent;
        color: $primary;
    }

    .beer-introduction {
        margin-top: -1rem;
        font-weight: bold;
        font-style: italic;
        margin-inline: auto;
        font-size: .8rem;
    }

    .sizes, .allergens {
        display: flex;
        justify-content: center;
        gap: 1rem;

        img {
            width: 1.3rem;
            height: 1.7rem;

        }

        .size {
            text-align: center;
        }


    }

    .allergen, .size {
        //width: max-content;
        font-size: .8rem;
        display: flex;
        gap: .3rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: "Staatliches", "sans-serif";

    }

    /************************* blocks ************************************/
    [class^="block"] {


        h2 {
            margin-bottom: 2rem;
            color: $primary;
        }

        p {
            margin-bottom: 1rem;
            line-height: 1.4;
        }

        &.block-text {
            text-align: left;
            max-width: 846px;

            &.full {
                max-width: 1440px;
                @include media-breakpoint-up(md) {
                    padding-inline: 15px;
                    padding: 0 2rem;
                }
            }
        }
    }

    .block-default {
        margin-bottom: 2rem;
        @include media-breakpoint-up(lg) {
            margin-bottom: 3rem;
        }
    }

    .block-galleries {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr;

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        &.nb-2 {
            @include media-breakpoint-up(md) {
                grid-template-columns: 1fr 1fr;
            }
        }
        @include media-breakpoint-up(lg) {
            grid-gap: 3rem;
        }

        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .legend {
            margin-top: .6rem;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .block-text-image {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include media-breakpoint-up(lg) {
            gap: 3rem;
            flex-direction: row;
            align-items: center;
            text-align: left;

            &.image-left {
                flex-direction: row-reverse;
            }
            .text,
            .img {
                flex: 1;
            }
            .text {
                align-self: baseline;
            }
        }
    }

    .block-video {
        iframe {
            display: block;
            width: 100%;
        }

        .container {
            border: 1px solid;
            padding: 0;
        }

        @include media-breakpoint-up(lg) {
            iframe {
                min-height: 600px;
            }
        }
    }
}

