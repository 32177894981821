.sections.block-actualites,
main.articles-section {
    .item {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        text-align: center;
        position: relative;
        height: 100%;
    }
    .category {
        position: absolute;
        top: 0;
        left: 0;
        background-color: $black;
        color: $white;
        font-size: 0.7rem;
        padding: 0.3rem;
        font-family: "Staatliches", "sans-serif";
    }
}
main.articles-section {
    h1 {
        color:#c04d00;
    }
    .articles {
        padding: 2rem 0;
        @include media-breakpoint-up(lg) {
            padding: 2rem 110px 4rem 110px;
        }

        .container {
            display: grid;
            gap: 2rem;



            .content {
                display: grid;
                gap: 1rem;

                h2 {
                    color: #C04D00;
                    text-align: center;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px; /* 120% */
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    @include media-breakpoint-up(lg) {
                        font-size: 22px;
                    }
                }
            }

            .date {
                color: $primary;
            }

            .description {
                margin-top: auto;
            }



            a {
                font: inherit;
            }
        }

        @include media-breakpoint-up(lg) {
            .container {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}
