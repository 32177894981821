.gammes {

    background: url("/images/alcoolapapa/product/gammes-bg-desktop.jpg") no-repeat;
    background-size: cover;
    @include media-breakpoint-up(lg) {
        background: url("/images/alcoolapapa/gammes-bg-desktop.jpg") no-repeat;
        background-size: cover;
    }
    text-align: center;
    padding-bottom: 5rem;
    .container {
        @include media-breakpoint-down(lg) {
            padding:0 0 60px 0;
        }
        h1 {
            font-size: 34px;
            font-family: "Playfair Display", "sans-serif";
            //     box-shadow: 0px 2px 14px #000;
            line-height: 36px;
            letter-spacing: 3px;
            color: white;
            text-transform: uppercase;
            margin-bottom: 30px;
            @include media-breakpoint-up(lg) {
                margin: 60px 0 60px 0;
                font-size: 48px;
            }
        }

        p {
            display: none;
        }
        .grid {
            @include media-breakpoint-down(md) {
                padding: 0 16px;
                overflow-x: scroll;
                scroll-snap-type: x mandatory;
                display: flex;
                gap: 16px;
                margin-bottom: 40px;
                justify-content: inherit;
            }

            .item {
                color: $white;
                p {
                    color: inherit;
                }
                @include media-breakpoint-up(md) {
                    & > h1  {
                        font-size: 25px;
                    }
                    & > p {
                        font-size: 16px;
                    }
                }
                @include media-breakpoint-down(md) {
                    a.img {
                        height: 185px;
                        width: 185px;
                    }

                    p {
                        width: 259px;
                    }

                }
            }
        }
    }

    &:before {
        height: 37px;
        background: url("/images/alcoolapapa/separator.svg") repeat-x;
        z-index: 1;

        content: "";
        top: 0;
        transform: translateY(-50%);
        bottom: unset;
        display: inherit;
        position: relative;
    }
}
.gamme-page {
    text-align: center;

    .hero {
        margin-bottom: 3rem;
        img {
            height: inherit;
        }
    }

    .top {
        h1 {
            color: $primary;
        }
        p {
            max-width: 780px;
            margin: 1rem auto;
        }
        h2 {
            color: $primary;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;

            font-family: "Oswald", "sans-serif";
            font-size: 38px;
            font-style: normal;
            line-height: normal;
            letter-spacing: 4px;
            text-transform: uppercase;
        }
    }

    /*.gammes {
        padding-bottom: 4rem;
   //     padding-top: 4rem;
        background: url("/images/alcoolapapa/gammes/gammes-section-bg-mobile.jpg") center;
        background-size: cover;
        position: relative;

        &:before {
            height: 37px;
            background: url("/images/alcoolapapa/separator.svg") repeat-x;
            z-index: 1;

            transform: translateY(-50%);
            top: 0;
            display: inherit;
            position: relative;
        }

        @include media-breakpoint-up(lg) {
            background: url("/images/alcoolapapa/gammes/gammes-section-bg-desk.jpg");
            background-size: cover;

        }
        .container {
            padding-top: 3rem;
        }
        h1 {
            margin: 0 0 2rem 0;
            color: $white;
            text-shadow: 0 2px 14px #000;
            font-family: "Playfair Display", "sans-serif";
            text-transform: uppercase;
            font-size: 34px;
            font-style: normal;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 3px;
            @include media-breakpoint-up(lg) {
                margin: 0 0 4rem 0;
                font-size: 48px;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 4px;
                text-shadow: none;
            }
        }
        p {
            display: none;
        }
        .grid {
            color: $white;
        }
    }*/

    .in-this-gamme {
        margin: 5rem 0;

        h1 {
            margin-bottom: 2rem;
        }

        h2 {
            font-size: 25px;
            /* font-style: normal; */
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 2px;
            text-transform: uppercase;

        }
        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .container {
            display: grid;
            gap: 3rem;
        }
    }

    @include media-breakpoint-up(md) {
        .in-this-gamme {
            .container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

.content-info-product {
    display: flex;
    gap: 1em;
    align-items: center;
    margin-top: 20px;

    img {
        width: 26px;
    }
}
