.gammes {
    .grid {
        display: grid;
        justify-content: center;
        text-align: center;
        align-items: flex-start;
        gap: 3rem;
    }

    a {
        display: block;
        font-size: to-rem(20);
    }

    .item {
        display: grid;
        gap: 0;
        p {
            color: inherit;
        }
        .img {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            background-color: gray;
            border-radius: 50%;
            width: 250px;
            height: 250px;
            margin: auto;
            margin-bottom: 1rem;

            img {
                height: 100%;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .grid {
            grid-template-columns: repeat(2, 1fr);
            padding-bottom: 20px;
        }
    }
    @include media-breakpoint-up(xl) {
        a {
            font-size: to-rem(30);
        }
        .grid {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
