.old {
    position: fixed;
    inset: 0;
    background-color: $primary;
    color: $white;
    z-index: 99999;
    display: grid;
    place-content: center;
    text-align: center;
    padding: 1rem;

    &, p {
        color: white !important;
    }

    .alert {
        background-color: $black;
        padding: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    .content {
        background-color: $black;
        padding: 2rem;
        border-radius: 1rem;
        max-width: 846px;
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        gap: 2rem;

        .button {
            margin: 0;
        }
    }

    img {
        max-width: 200px;
        margin-inline: auto;
        margin-bottom: 2rem;
    }
}
