.search-modal {
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    //  z-index: 10;
    background-image: url("/images/alcoolapapa/product/product-bg-mobile.jpg");
    background-attachment: fixed;
    @include media-breakpoint-up(lg) {
        background-image: url("/images/alcoolapapa/product/product-bg-desktop.jpg");

    }
    transition: .3s ease;

    &.show {
        left: 0;
    }

    & > div:first-child {
        height: 100%;
        position: relative;
    }

    .container {
        margin-top: 7rem;
        width: 100%;
        max-width: 830px;
        align-items: flex-start;
        height: 100%;
    }

    form {
        width: 100%;
    }

    .input-container {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 34px;
        align-items: center;


        @include media-breakpoint-up(lg) {
            gap: 61px;
            width: max-content;
        }

        .sap-man {
            display: none;
            @include media-breakpoint-up(lg) {
                display: inherit;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(25%);
                background: url("../../../images/alcoolapapa/sap-man.svg") no-repeat;
                background-size: contain;
                width: 397px;
                height: 347px;
            }
        }

        svg {
            path {
                fill: $primary;
            }

            @include media-breakpoint-up(lg) {
                position: inherit !important;
                top: inherit !important;
                left: inherit !important;
                transform: inherit !important;
            }
        }

        input#query-input {
            color: $black;
            background-color: $white !important;
            padding: 30px 20px;
            // padding: 0.5rem .5rem .5rem 2.5rem;
            width: 100%;

            &::placeholder {
                font-size: .8rem;
            }

            @include media-breakpoint-up(lg) {
                width: 838px;
            }
        }

        input.submit {
            font-size: 16px;
            letter-spacing: 4px;
            background-color: $primary;
            color: $white;
            padding: 9px 20px;
            cursor: pointer;
            transition: 0.3s ease;

            &:hover {
                color: $primary;
                background-color: $white;
            }
        }
    }


    svg {
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: .5rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }


    .search-container {

        text-align: left;

        height: 100%;

        @include media-breakpoint-up(lg) {
            margin-top: 140px;
        }

        h2 {
            font-size: 34px;
            font-family: Playfair Display;
            font-style: normal;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 3px;
            text-align: center;
            color: $primary;
            margin-bottom: 1.5rem;

            @include media-breakpoint-up(lg) {
                font-size: 48px;
                margin-bottom: 40px;
            }

        }

        .logo {
            width: 144px;
            height: 126px;
            margin: auto;
            margin-bottom: 44px;
            margin-top: 70px;
            background: url('../../../images/alcoolapapa/search/search-modal-logo-bg-mobile.svg') no-repeat;
            background-size: contain;

            @include media-breakpoint-up(lg) {
                background: url('../../../images/alcoolapapa/search/search-modal-logo-bg-desktop.svg') no-repeat;
                background-size: contain;
                position: absolute;
                margin: 0;
                left: 140px;
                top: 20px;
            }
        }

        .socials {
            display: flex;
            margin-top: 83px;
            gap: 70px;
            justify-content: center;
            @include media-breakpoint-up(lg) {
                position: absolute;
                bottom: 92px;
                right: 129px;
                gap: 30px;
            }

            a.button {
                background: none;
                margin: 0;
                padding: 0;

                border: 1px solid white;
                background: $white;
                width: 71px;
                height: 71px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s ease;

                &:hover {
                    background: $primary;
                    border-color: $primary;

                    & > svg > path {
                        fill: $white;
                    }
                }

                svg {
                    transform: inherit;
                    position: inherit;
                    left: inherit;
                    top: inherit;

                    & > path {
                        fill: $primary;
                    }
                }
            }
        }


        .socials {
            display: flex;
            margin-top: 83px;
            gap: 70px;
            justify-content: center;
            @include media-breakpoint-up(lg) {
                position: absolute;
                bottom: 92px;
                right: 129px;
                gap: 30px;
            }

            a.button {
                background: none;

                margin: 0;
                padding: 0;

                border: 1px solid white;
                background: $white;
                width: 71px;
                height: 71px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s ease;

                &:hover {
                    background: $primary;
                    border-color: $primary;

                    & > svg > path {
                        fill: $white;
                    }
                }

                svg {
                    transform: scale(1.5);
                    @include media-breakpoint-up(lg) {
                        transform: inherit;
                        position: inherit;
                        left: inherit;
                        top: inherit;
                        & > path {
                            fill: $primary;
                        }
                    }
                }
            }
        }

        form {
            flex-direction: column;
            align-items: center;
            gap: 2rem;
        }

        .search-result {
            height: auto;
            max-height: calc(100vh - 20rem);
            border-radius: .3rem;
            font-size: .8rem;

            &.no-result {
                padding: .5rem 1rem;
            }

            a {
                min-height: auto;
                font-family: inherit;
            }
        }

        .hit {

            align-items: center;
            margin: 0.5rem .5rem;

            img {
                width: 2.5rem;
                height: 2.5rem;
                object-fit: cover;
            }

            &:hover {
                color: $primary;
                background-color: transparent;
            }
        }

        .hit:not(:first-child) {
            border-top: 1px solid gray;

        }


    }

    @include media-breakpoint-up(lg) {
        .container {
            margin-top: 10rem;

        }
    }

    .separator {

        height: 37px;
        background: url("/images/alcoolapapa/separator.svg") repeat-x;
        z-index: 1;

        content: "";
        top: 0;
        display: inherit;

        width: 100%;
        position: absolute;
        bottom: 5px;
        left: 0;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}
