footer {
    //background:#702D09 url("../../../images/alcoolapapa/footer/footer-bg-mobile.png") no-repeat;
    background-image: url("../../../images/alcoolapapa/footer/footer-bg-desktop.jpg");
    //background-size: cover !important;
    color: $white;
    font-weight: bold;
    position: relative;
    text-align: center;
    margin-top: auto;

    .burger-menu-logo {
        display: none !important;
    }

    text-shadow: black 1px 1px 1px;

    nav ul li a {
        color: #FFF;
        text-align: right;
        font-family: Roboto, "sans-serif" !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    @include media-breakpoint-up(lg) {
        background-image: url("../../../images/alcoolapapa/footer/footer-bg-desktop.jpg");
        background-size: cover;
    }

    &:before {
        height: 37px;
        background: url("/images/alcoolapapa/separator.svg") repeat-x;
        z-index: 1;

        content: "";
        top: 0;
        transform: translateY(-50%);
        bottom: unset;
        display: inherit;
        position: relative;
    }

    .container {
        display: grid;
        gap: 1rem;
    }

    .left,
    .right {
        display: grid;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

    .right {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;

        .tel, .adress {
            a {
                font-family: "Roboto", "sans-serif" !important;
            }
        }

        div.tel {
            margin-bottom: 57px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

        div.adress {
            margin-bottom: 47px;
            max-width: 270px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                max-width: 340px;
            }
        }
    }

    .left {
        @include media-breakpoint-up(lg) {
            height: 100%;
        }

        a {
            font-family: inherit;
            @include media-breakpoint-up(lg) {
                height: 100%;
            }

            img {
                width: 168px;
                height: 148px;
                margin-bottom: 30px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                    height: 100%;
                    width: inherit;
                }
            }
        }
    }


    .btn-section {
        display: flex;
        flex-direction: column;
        gap: 50px;

        @include media-breakpoint-up(lg) {
            flex-direction: column-reverse;
            gap: 40px;
        }
    }

    .socials {
        display: flex;
        gap: 15px;
        @include media-breakpoint-up(lg) {
            gap: 30px;
        }

        a.button {
            margin: 0;
            width: 71px;
            height: 71px;
            align-content: center;
            justify-content: center;
            display: grid;
            background-color: $white;
            border: 2px solid $white;
            color: $primary;
            transition: 0.3s ease;

            @include media-breakpoint-up(lg) {
                margin: 0;
            }

            svg {
                //  width: 2rem;
                path {
                    fill: $primary;
                }
            }

            &:hover {
                background: $primary;
                border-color: $primary;
            }

            &:hover > svg > path {
                fill: $white;
            }
        }
    }

    .button.white {
        // padding: 0 2rem;
        padding: 9px 24px;
        letter-spacing: 4px;
        font-size: 16px;
        margin: inherit;
        text-shadow: none;
        @include media-breakpoint-down(lg) {
            align-self: center;
        }

        &:hover {
            background: $primary;
            color: $white;
            border-color: $primary;
        }
    }

    .button.radius {
        border-radius: inherit !important;
    }

    @include media-breakpoint-up(lg) {
        text-align: left;

        .container {
            grid-template-columns: auto auto;
            align-items: center;
            justify-content: center;
            gap: 15rem;

            @include media-breakpoint-up(lg) {
                margin-top: 50px !important;
                margin-bottom: 90px !important;
            }
        }
        .logo {
            width: 200px;
            @include media-breakpoint-up(lg) {
                height: 272px;
                width: 311px;
            }
        }
    }

    .lang-hamburger {
        display: none;
    }

    ul {
        display: flex;
        justify-content: center;
        gap: 15px;
        margin-top: 50px;
    }

    li {
        &:last-child {
            content: '-';
            display: inline-block;
        }

        &:hover {
            text-decoration: underline;
        }

        a {
            font-family: 'Roboto', 'sans-serif';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
        }
    }

    .info {
        font-size: 14px;
        text-align: center;
        //margin-top: 40px;
        margin: 40px 0;
    }

    .menu .socials {
        display: none;
    }
}
