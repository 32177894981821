// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Oswald:wght@400&family=Playfair+Display&family=Staatliches&display=swap');

// Commun
@import "commun/variables";
@import "commun/mixins";
@import "commun/reset";
@import "commun/functions";
@import "commun/globals";

// Partials
@import "partials/header";
@import "partials/hero";
@import "partials/hero-page";
@import "partials/gammes";
@import "partials/articles";
@import "partials/footer";
@import "partials/search-modal";
@import "partials/map";
@import "partials/cookies";
@import "partials/old";
@import "partials/share-section";
@import "partials/in-this-gamme";

//Pages
@import "pages/home-page";
@import "pages/gamme-page";
@import "pages/page";
@import "pages/search-page";
@import "pages/error-page";
@import "pages/beer-page";
