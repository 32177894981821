main {
    flex-grow: 1;
    background-position: center center;
    background-image: url("/images/alcoolapapa/product/product-bg-mobile.jpg");
    background-attachment: fixed;
    @include media-breakpoint-up(lg) {
        background-image: url("/images/alcoolapapa/product/product-bg-desktop.jpg");

    }
}
.page {
    text-align: center;

    &.hasMap {
        .container:not(.share-section) {
            display: inline-block;
            margin: 50px auto !important;
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }


    .block-galleries, .block-text-image, .block-text, .center {
        margin-inline: auto;
        padding: 0 2rem;
    }


    .block-text {
        margin: 2rem auto;
        padding: 0 2rem;
        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            padding: 0;
        }
    }


    [class^=block].block-text {
        //text-align: inherit !important;
    }

    .description {
        margin: 2rem auto;
    }

    .container.top {
        max-width: inherit;
        margin-block: 0;
        position: relative;
        padding: 0;

        &:has(div#map) {
            @include media-breakpoint-down(md) {
                margin-top: 40px;
            }
        }

        h1 {
            color: $primary;
            text-transform: uppercase;
        }

        div:not(.full) {
            &:not(.block-text) {
                max-width: 1440px;
                margin-inline: auto;
            }
        }

        p {
            line-height: calc(29 / 19 * 1em);
            font-style: normal;
        }
    }

    a.return-articles {
        display: inline-block;
        color: $primary;
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 70px auto 100px auto;
        text-transform: lowercase;

        &:first-letter {
            text-transform: uppercase;
        }
    }
}
