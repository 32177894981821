@mixin media-breakpoint-up($name) {
    @media all and (min-width: map-get($breakpoints, $name)) {
        @content;
    }
}

@mixin media-breakpoint-down($name) {
    @media all and (max-width: map-get($breakpoints, $name)) {
        @content;
    }
}

@mixin element-color($color) {
    background-image: url('data:image/svg+xml;utf8,<svg ...><g stroke="#{$color}" ... /></g></svg>');
}
