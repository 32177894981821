.share-section {
    display: flex;
    justify-content: end;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    a {
        color: $primary;
        background-color: $white;
        padding: 8px 13px;
        transition: 0.3s ease;
        &:hover {
            color: $white;
            background-color: $primary;
        }
    }
}
