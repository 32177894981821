.search-page {
    padding-top: 9rem;
}

.search-container {
    width: 100%;
    text-align: center;

    button:disabled,
    button[disabled]{
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }
    .h1,.picto {
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 2rem;
    }

    .picto svg {
        width: 4rem;
    }
    form {
        display: flex;
        gap: 1rem;
        input {
            height: 50px;
            flex: 1;
        }
        button {
            margin: 0;
        }
    }

}

.search-result {
    background-color: $white;
    margin-top: 15px;
    color: $black;
    border: 1px solid $primary;
    overflow: scroll;
    height: calc(100vh - 220px);
    .hit {
        display: flex;
        gap: 1rem;
        padding: 10px;
        transition: all ease .3s;
        &:hover {
            background-color: $primary;
            color: $white;
            transition: all ease .3s;
        }
    }

    a {
        text-align: left;
        min-height: 80px;
    }

    img {
        flex: 0;
    }

    .description {
        font-family: "Roboto", sans-serif;
    }
}
