.cookies {
    background: $primary;
    padding: 1rem;
    font-size: .8rem;
    color: $white;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 99;

    .toggle {
        display: flex;
        align-items: center;
        gap: 1rem;
    }


    .buttons {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;
    }

    .text {
        max-width: 1000px;
        margin: auto;
    }

    button {
        border: 2px solid $white;
        display: block;
        padding: 4px 12px;
        transition: .3s ease;

        &:hover {
            background-color: $white;
            color: $black;
        }

        &.accept {
            background-color: $white;
            color: $black;

            &:hover {
                background-color: transparent;
                color: $white;
            }
        }
    }
}

iframe {
    .cookies {
        display: none !important;
    }
}
