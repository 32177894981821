*,
::after,
::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font: inherit;
    color: inherit;
}
body.alcoolapapa {
    button,
    input {
        outline: none;
        border: none;
        background-color: transparent;
        display: block;
    }

    button {
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }

    ul {
        list-style: none;
    }
}

