.header {
    color: $white;
    padding: 1rem 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    transition: 0.5s;

    background-color: rgba(0, 0, 0, 0.7);
    @include media-breakpoint-up(lg) {
        background-color: rgba(255, 255, 255, 0.7);
    }

    .header-logo {
        background: url('/images/alcoolapapa/sap-logo-header.svg') no-repeat;
        background-size: contain;
        width: 72px;
        height: 72px;
        margin-top: -10px;
        margin-bottom: -12px;

        @include media-breakpoint-down(lg) {
            width: 48px;
            height: 48px;
            margin-top: 0;
            margin-bottom: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url('/images/alcoolapapa/sap-logo-header-mobile.svg') no-repeat;

        }
    }

    button.search-btn {
        svg.open {
            path {
                fill: $secondary;
                @include media-breakpoint-up(lg) {
                    fill: $primary;
                }
            }
        }

        /*        &.active {
                    z-index: 11;
                    position: absolute;
                    right: 22px;
                    top: 22px;
                }*/
        &.active {
            transform: translateY(0);
            z-index: 11;

            .open {
                display: none;
            }

            .close {
                display: block;
            }
        }
    }

    &.menu-showed {


        .hamburger-btn {
            color: $secondary;
            @include media-breakpoint-up(lg) {
                color: $white;
            }

        }

        .search-btn {
            svg {
                path {
                    fill: $secondary;
                    @include media-breakpoint-up(lg) {
                        fill: $primary;
                    }
                }
            }
        }
    }


    &.scrolled {
        background-color: rgba(0, 0, 0, 0.7);
        @include media-breakpoint-up(lg) {
            background-color: rgba(255, 255, 255, 0.7);
        }
    }

    img.white {
        display: block;
    }

    img.black {
        display: none;
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1.5rem;
    }

    .search-btn {
        position: relative;
        z-index: 1;
        @include media-breakpoint-up(lg) {
            margin-left: auto;
        }
        transform: translateY(4px);

        .close {
            display: none;
        }

        svg {
            width: 20px;
            height: 20px;
        }

        &.active {
            transform: translateY(0);

            .open {
                display: none;
            }

            .close {
                display: block;
                width: 20px;
                height: 20px;

                path {
                    fill: $black;
                    @include media-breakpoint-up(lg) {
                        fill: $primary;
                    }
                }
            }
        }
    }

    .lang-switcher {
        position: relative;
        z-index: 5;
        font-family: inherit;

        .active {
            font-weight: 900;
        }
    }

    .swap-link {
        position: relative;
        display: none;
        color: $primary;
        font-size: 14px;
        font-family: "Roboto", sans-serif;
        letter-spacing: 0.5px;
        font-weight: bold;
        transition: 0.5s;
        svg path {
            fill: $primary;
        }
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
        }

    }

    .hamburger-btn {
        position: relative;
        z-index: 5;

        .close {
            display: none;
        }

        &.clicked {
            .close {
                display: block;
                height: 20px;
                width: 20px;
            }

            .open {
                display: none;
            }
        }
    }

    nav {

        .lang-switchers {
            color: $primary;
            display: none;
            @include media-breakpoint-up(lg) {
                //display: inherit;
                z-index: 1;
            }
        }

        .menu {
            position: absolute;
            //  overflow: auto;
            background: url("/images/alcoolapapa/product/gammes-bg-desktop.jpg") no-repeat;
            background-size: cover;
            @include media-breakpoint-up(lg) {
                background: url("../../../images/alcoolapapa/product/gammes-bg-desktop.jpg") no-repeat;
                background-size: cover;
            }

            width: 100%;
            left: 0;
            top: 0;
            height: 0;
            overflow: hidden;
            transition: .3s ease height;

            img.burger-menu-logo {
                display: none;
            }

            &.show-menu {
                height: 100vh;
                overflow: auto;
                display: flex;
                flex-direction: column;

                img.burger-menu-logo {
                    display: inherit;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 67px;
                    margin-bottom: 40px;
                    width: 144px;
                    height: 126px;
                    @include media-breakpoint-up(lg) {
                        margin: 0;
                        position: absolute;
                        top: -80px;
                        left: 0;
                        transform: translate(140px, 100px);
                        max-width: 15%;
                        width: 220px;
                        height: auto;
                        margin-bottom: 50px;
                    }
                }

                .socials-desutter {
                    display: flex;
                }

                .separator {
                    width: 100%;
                    height: 37px;
                    background: url("/images/alcoolapapa/separator.svg") repeat-x;
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
            }

            > ul {
                @include media-breakpoint-up(lg) {
                    margin-top: 142px;
                }
            }

            > ul > li {
                margin: 1rem 2rem;
                text-align: center;
                font-size: 30px;
                letter-spacing: 4px;


                > a {
                    font-size: to-rem(25);
                    font-weight: bold;
                    font-family: "Playfair Display", "sans-serif";
                    text-transform: uppercase;

                    @include media-breakpoint-up(lg) {
                        font-size: to-rem(34);
                        font-style: normal;
                        font-weight: 500;
                        line-height: 30px;
                        letter-spacing: 4px;
                    }
                }
            }


            .sub-menu {
                padding: 1rem 2rem;
                display: none;

                &.show-sub-menu {
                    display: block;

                    li {
                        font-size: to-rem(21);
                    }
                }

                li {
                    margin: 0.5rem;
                }
            }
        }

        .lang-hamburger {
            display: flex;
            align-items: center;
            gap: 1.5rem;

            .hamburger-btn {
                height: 26px;
                width: 26px;

                svg.open {
                    height: 26px;
                    width: 26px;

                    path {
                        fill: $secondary;
                        @include media-breakpoint-up(lg) {
                            fill: $primary;
                        }
                    }
                }
            }
        }
    }


    .socials-desutter {
        margin-top: auto;
        display: flex;
        justify-content: space-around;
        margin-bottom: 34px;

        .desutter {
            @include media-breakpoint-up(lg) {
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 85px;
                gap: 27px;
            }

            p {
                color: inherit;
            }

            .logo {
                background: url("../../../images/alcoolapapa/desutter-logo.svg") no-repeat;
                background-size: contain;
                display: inherit;
                width: 145px;
                height: 67px;
            }
        }

        div.socials {
            align-items: flex-end;
            display: flex;
            gap: 2rem;

            @include media-breakpoint-up(lg) {
                width: max-content;
                position: absolute;
                bottom: 85px;
                right: 140px;
                justify-content: center;

            }

            a {
                border: 1px solid $white;
                background: $white;
                height: 71px;
                width: 71px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s ease;

                &:hover {
                    background: $primary;
                    border-color: $primary;

                    svg > path {
                        fill: $white;
                    }
                }

                svg {
                    height: 30px;

                    path {
                        fill: $primary;
                    }
                }
            }
        }

    }

    @include media-breakpoint-up(lg) {
        nav {
            .menu {

                text-align: center;

                > li {

                    &:first-child {
                        margin-top: 10rem;
                    }
                }
            }
        }
    }

    .search-lang-btn {
        color: $white !important;

        svg {
            path {
                fill: $white !important;
            }
        }
    }

    .burger-btn {
        svg {
            path {
                @include media-breakpoint-down(lg) {
                    fill: $black !important;
                }
            }
        }
    }
}

.black-header .header {

    .hamburger-btn {
        &.clicked {
            .close {
                display: block;
            }

            .open {
                display: none;
            }

        }
    }

}
